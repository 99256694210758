<script>
  import { onMount } from 'svelte';
  import { createEventDispatcher } from 'svelte';
  import { get } from 'svelte/store';
  import { push } from 'svelte-spa-router';
  import switchboardClient from '../../../js/services/switchboard';
  import { 
    applicationFlow,
    seatSelectionPaymentAccepted,
    seatPaymentAmountPerPassenger,
    CustomErrorModal,
    booking,
    originalSeatOfPassengers,
    checkoutFormPayload,
    didAnyPassengerSeatChange,
    priceUnpaidItemsData,
    seatPaymentProcessInProgress,
    errorModal
  } 
  from '../../../js/stores';
  import { 
    ApplicationFlow,
    REFERENCE_OWNER,
    REFERENCE_TYPE,
    MERCHANT_CODE,
    TEMPLATE_ID,
    LANGUAGE,
    SESSION_TIMEOUT,
    OPERATOR_CODE 
  } from '../../../js/const';
  import logger from '../../../js/logger';
  import endTransaction from '../../../js/endTransaction';
  import { ErrorModals } from '../../../js/const/errorModals';
  import flightdeck from '../../../js/services/flightdeck';
  import flightdeckConsts from '../../../js/services/flightdeck/const';
  import { SeatPayment } from '../../../js/controllers';
  import SeatChangePaymentRequired from '../modal/SeatChangePaymentRequired.svelte'
  import CabinContainer from './CabinContainer.svelte';
  import Destination from './Destination.svelte';
  import Footer from './Footer.svelte';
  import LoadingAnimation from '../LoadingAnimation.svelte';
  import OtherAirlineMessage from './OtherAirlineMessage.svelte';
  import PassengerSelect from './PassengerSelect.svelte';
  import Payment from '../modal/Payment.svelte';
  import ScrollablePane from '../ScrollablePane.svelte';
  import ScrollablePaneHeader from './ScrollablePaneHeader.svelte';
  import SeatContent from './SeatContent.svelte';
  import SegmentSelector from './SegmentSelector.svelte';

  export let description = null;
  export let flightNumber = null;
  export let passenger = null;
  export let passengers = null;
  export let passengerSeats = null;
  export let previewSeat = null;
  export let hasError = null;
  export let isLoading = null;
  export let isOpen = null;
  export let seat = null;
  export let seatMapManager = null;
  export let segments = null;
  export let segment = null;
  export let selectedSeat = null;

  const BASE_REM = 16;
  const dispatch = createEventDispatcher();
  const SCROLLABLE_PANE_PBD_HEIGHT = 58; // rem
  const SCROLLABLE_PANE_SELF_SERVICE_HEIGHT = 58; // rem
  const seatSpace = 0.5; // rem
  const seatWidth = 3; // rem
  const spacerWidth = 3.25; // rem

  let subtractScrollablePaneHeaderHeights = null;
  let cabinHeight = null;
  let cabinWidth = null;
  let combinedSpacerWidth = null;
  let declinedPaymentModal;
  let hasScrollbar = false;
  let paneHeight = null;
  let scrollablePaneHeaderHeight = null;
  let selectorHeight = null;
  let seatPayment;
  let showPaymentModal = false;
  let showSuccess = false;
  let showPaymentWarningModal = false;
  let seatChangeChargesNotFound;
  let unpaidItems = [];

  $: if (showPaymentModal) {
    // show loading if showPaymentModal is true, else do not show loading
    isLoading = showPaymentModal;

    if (unpaidItems && unpaidItems?.length > 0) {
      let totalAmount = 0;
      unpaidItems.forEach(s => {
        totalAmount = Number(totalAmount) + Number(s.price);
      });

      logger.info(`total amount of seat payment is: ${totalAmount}`);

      let currency = unpaidItems[0]?.currency;

      seatPayment = new SeatPayment(totalAmount, currency, handleAccepted, handleDeclined);

      logger.info(`SeatPayment class object is: ${seatPayment}`);

      let seatPaymentAmount = get(seatPaymentAmountPerPassenger);
  
      unpaidItems.forEach(s => {
        seatPayment.addItem(
          Number(s.price),
          Number(s.price),
          seatPaymentAmount.filter(s => s.passengerID === s.passengerID)[0]?.seatNumber,
          seatPaymentAmount.filter(s => s.passengerID === s.passengerID)[0]?.seatNumber
        );
      });

      logger.info(`Creating checkout form payload for seat payment using reference: ${unpaidItems[0].serviceValue}`);

      checkoutFormPayload.set(
      {
        data: {
          template: {
            merchantCode: MERCHANT_CODE,
            id: TEMPLATE_ID,
            configuration: {
              language: LANGUAGE,
              sessionTimeout: SESSION_TIMEOUT,
              labels: {
                'details/items[0]/label': 'Seat Payment',
                'details/items[0]/value': `${totalAmount}.00 ${currency}`
              },
              customizations: {
                logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/0/0e/Etihad-airways-logo.svg'
              },
              redirectUrls: [
                {
                  rel: 'failure',
                  href: 'https://www.google.com/search?q=failure'
                },
                {
                  rel: 'TermsAndConditions',
                  href: 'https://www.google.com/search?q=terms'
                }
              ]
            }
          },
          salesSummary: {
            totalPrice: {
              value: totalAmount * 100,
              decimalPlaces: 2,
              currency: `${currency}`
            },
            reference: get(booking).bookingReference, // unpaidItems[0].serviceValue,
            referenceType: REFERENCE_TYPE,
            referenceOwner: REFERENCE_OWNER
          }
        }
      }
    );
    }
    
    declinedPaymentModal = new CustomErrorModal(
      ErrorModals.SEAT_PAYMENT_FAILED,
    );

    declinedPaymentModal.setFlightdeckHandler(
      flightdeckConsts.TransactionStatuses.Cancelled,
      handleFlightDeckCancelled,
    );

    declinedPaymentModal.setEndHandler(
      handleFlightDeckCancelled
    );
  } else if (seatPayment) {
    seatPayment.cancelPolling();
  }

  $: airlineCode = segment ? segment.airlineCode : '';

  /** Calculate the total width of the space between seats. */
  $: combinedSpacerWidth =
    (seatMapManager ? seatMapManager.maxGroups() - 1 : null) * spacerWidth;

  /** Calculate how wide the seats inside the cabin should be. */
  $: cabinWidth = seatMapManager
    ? seatMapManager.maxSeats() * (seatWidth + seatSpace) + combinedSpacerWidth
    : null;

  /** Check for height change when components like Advertisement are active. */
  $: scrollablePaneHeaderHeight;

  /** Calculate the subtraction amount of height for ScrollablePaneHeader. */
  $: subtractScrollablePaneHeaderHeights =
    (selectorHeight + scrollablePaneHeaderHeight) / BASE_REM;

  /** Calculate the height for ScrollablePane. */
  $: paneHeight =
    get(applicationFlow) === ApplicationFlow.PORTER_BAG_DROP
      ? SCROLLABLE_PANE_PBD_HEIGHT - subtractScrollablePaneHeaderHeights
      : SCROLLABLE_PANE_SELF_SERVICE_HEIGHT -
        subtractScrollablePaneHeaderHeights; // rem

  /** Calculate if a scrollbar should be shown. */
  $: if (cabinHeight / BASE_REM > paneHeight) {
    hasScrollbar = true;
  }

  onMount(() => { })

  /** End transaction due to Flightdeck cancel. */
  function handleFlightDeckCancelled() {
    endTransaction();
    return true;
  }

  /** Handle a successful payment. */
  function handleAccepted() {
    showPaymentModal = false;
    showSuccess = true;
  }

  /** Handle a declined payment. */
  function handleDeclined() {
    flightdeck.seatChangeFailed();
    showPaymentModal = false;
    declinedPaymentModal.open();
  }

  /** Handle continuation after successful payment. */
  function handleContinue() {
    flightdeck.seatChangePaymentSuccessful();    
    logger.info(`Payment completed successfully. Going to Number of bags screen.`);
    showSuccess = false;
    push('/number-of-bags');
  }

  function agentAllowedRetryPayment() {
    errorModal.reset();
    advanceToNextScreen();
  }

  /** Advance to the next screen as per the applicationFlow. */
  function advanceToNextScreen() {
    let seatPaymentAmount = get(seatPaymentAmountPerPassenger);
    let totalAmount = 0;

    if (seatPaymentAmount && seatPaymentAmount?.length > 0) {
      seatPaymentAmount.forEach(s => {
        totalAmount = totalAmount + s.amount;
      });
    }
    
    // check if we need to collect payment
    if (totalAmount > 0) {
      logger.info(`seat ancillary amount is not paid. calling unpaid ancillaries...`);
      switchboardClient.priceUnpaidItems(get(booking), false)
        .then((response) => {
          logger.info(`Received priceUnpaidItems response.`, JSON.stringify(response));
          if (response && response?.data && response?.data?.priceUnpaidItems) {
            let res = response?.data?.priceUnpaidItems;
            if (res) {
              if (!res?.isGetSuccessful || res.unpaidItems?.length <= 0) {
                logger.info(`Price unpaid items not received. Moving to Number of bags screen`);
                push('/number-of-bags');
              } else {
                isOpen = false;
                priceUnpaidItemsData.set(response);
                seatPaymentProcessInProgress.set(true);
                res.unpaidItems.forEach(ui => {
                  unpaidItems.push(
                    {
                      pricingRecordId: ui.pricingRecordId,
                      price: Number(ui.price),
                      currency: ui.currency,
                      serviceValue: ui.serviceValue,
                      passengerID: ui.passengerID
                    }
                  );
                });

                logger.info(`unpaid items object is: ${JSON.stringify(unpaidItems)}`);
                showPaymentModal = true;
              }
            } 
          }
        });
    } else {
      push('/number-of-bags');
    }
  }

  function handleFlightDeckOverride() {
    // Due to unavailability of charges, Agent has overridden the transaction
    // means passenger seats would be changed back to original seats
    isLoading = true;

    logger.info(`Undoing seat changes as agent has clicked override`);
    undoSeatChanges();  

    // finally going to number of bags screen
    setTimeout(() => {
      isLoading = false;
      logger.info(`Agent override complete. Seat changes undone. Now going to number of bags screen. waiting for 3 seconds before ending transaction so that seat changes can be undone.`)
      push('/number-of-bags');
    }, 3000);
    return true;
  }

  function undoSeatChanges() {
    let orgPassengerSeats = get(originalSeatOfPassengers);
    if (orgPassengerSeats && orgPassengerSeats?.length > 0) {
      orgPassengerSeats.forEach(ops => {
        let passenger = get(booking).passengers.filter(p => p.passengerID === ops.passengerID && p.passengerDID === ops.passengerDID);
        if (passenger && passenger?.length > 0)
        logger.info(`Request Re-allocateSeat for passenger ${JSON.stringify(passenger[0])}.`);

        switchboardClient
          .allocateSeat(booking, passenger[0], ops.flightNumber, ops.seatNumber, 0)
          .then((response) => {           
            logger.info(`AllocateSeat response is : ${JSON.stringify(response)}).`); 
            let errorMessages = response?.data?.allocateSeat?.errorMessages;
            if (errorMessages && errorMessages?.length > 0) {
              errorMessages.forEach((error) => {
                logger.info(error);
              });
              logger.info('Returning false from Allocate seat...');
              return false;            
            } else {
              didAnyPassengerSeatChange.set(true);
            }
          });      
      });
    }
  }

  function handlePassengerAccept() {
    flightdeck.seatChangePaymentWarningAccepted(`Passenger selected seat ${String(seat)} which incurs a cost of ${seat.getPrice()} AED. Passenger has accepted to pay.`);   
    showPaymentWarningModal = false;
    logger.info(`Passenger has accepted to pay for the seat change. Now changing the seat`);
    
    let seatSelectionPaymentAcceptance = get(seatSelectionPaymentAccepted);
    seatSelectionPaymentAcceptance.push({ passengerID: passenger.passengerID, isAccepted: true});
    seatSelectionPaymentAccepted.set(seatSelectionPaymentAcceptance);
    
    dispatch('updateSelectedSeat', {
        passenger,
        seat: String(seat),
        amount: seat.getPrice()
    });

    // update the seat price per passenger object
    let seatPaymentAmount = get(seatPaymentAmountPerPassenger);
    if (seatPaymentAmount && seatPaymentAmount?.length > 0) {
      let isPassengerFound = false;
      seatPaymentAmount.forEach(s => {
        if (s.passengerID === passenger.passengerID) {
          isPassengerFound = true;
          s.amount = seat.getPrice(),
          s.seatNumber = String(seat)
        }
      });
      if (!isPassengerFound) {
        seatPaymentAmount.push({ passengerID: passenger.passengerID, amount: seat.getPrice(), seatNumber: String(seat) });
      }
      seatPaymentAmountPerPassenger.set(seatPaymentAmount);
    } else {
      seatPaymentAmountPerPassenger.set([{ passengerID: passenger.passengerID, amount: seat.getPrice(), seatNumber: String(seat) }]);
    }
  }

  function handlePassengerDecline() {
    showPaymentWarningModal = false;
    logger.info(`Passenger has declined to pay for the seat change.`);
    flightdeck.seatChangePaymentWarningDeclined();
  }

  function handlePassengerPaymentDecline() {
    logger.info(`Passenger has declined to pay for the seat change on QR Code page.`);
    
    const optedOutOfUpgradeError = new CustomErrorModal(
      ErrorModals.OPTED_OUT_OF_SEAT_CHANGE,
      {
        flightdeckHandlers: {
          [flightdeckConsts.TransactionStatuses.Cancelled]:
            handleFlightDeckCancelled,
          [flightdeckConsts.TransactionStatuses.Overridden]:
            handleFlightDeckOverride,
          [flightdeckConsts.TransactionStatuses.PaymentPending]:
            agentAllowedRetryPayment,
        },
      },
    );
    optedOutOfUpgradeError.setOverrideHandler(handleFlightDeckOverride);
    optedOutOfUpgradeError.setEndHandler(handleFlightDeckCancelled);

    optedOutOfUpgradeError.open();

    flightdeck.declinedSeatPayment();
  }
</script>

<Payment
  controller={seatPayment}
  {handleContinue}
  bind:showModal={showPaymentModal}
  bind:showSuccess
  handleDeclined={handlePassengerPaymentDecline}
/>

<SeatChangePaymentRequired
  bind:showModal={showPaymentWarningModal}
  passengerAcceptHandler={handlePassengerAccept}
  passengerDeclineHandler={handlePassengerDecline}
/>

<div class="h-52">
  {#if isLoading}
    <LoadingAnimation class="mx-auto w-90" />
  {:else}
    <Destination {seatMapManager} {segment} />
  {/if}
</div>

<div bind:clientHeight={selectorHeight}>
  <PassengerSelect
    {flightNumber}
    {selectedSeat}
    {passengers}
    {passenger}
    on:passengerChange
  />
  {#if segments.length > 1}
    <SegmentSelector bind:isOpen {segment} {segments} on:segmentSelect />
  {/if}
</div>

<div class="border border-current relative" dir="ltr">
  {#if isLoading}
    <div style="height: {paneHeight}rem;">
      <div class="flex h-full items-center px-18">
        <LoadingAnimation class="mb-8 w-full" />
      </div>
    </div>
  {:else if airlineCode && airlineCode !== OPERATOR_CODE}
    <OtherAirlineMessage {paneHeight} />
  {:else}
    <ScrollablePaneHeader
      bind:scrollablePaneHeaderHeight
      {cabinWidth}
      controller={seatPayment}
      {flightNumber}
      {hasScrollbar}
      {passengers}
      {seatMapManager}
      {seatSpace}
      {seatWidth}
      {seat}
      {segment}
      {spacerWidth}
    />
    <ScrollablePane
      {paneHeight}
      scrollablePaneHeaderHeight={scrollablePaneHeaderHeight / BASE_REM}
      on:scrollToSeat
    >
      <CabinContainer
        bind:cabinHeight
        bind:isOpen
        {cabinWidth}
        {passenger}
        {passengerSeats}
        {previewSeat}
        {seatMapManager}
        {seatSpace}
        {seatWidth}
        {selectedSeat}
        {spacerWidth}
        on:selectSeat
        on:unSelectSeat
        on:scrollToSeat
      />
    </ScrollablePane>
    <SeatContent
      bind:isOpen
      bind:hasError
      {description}
      {passenger}
      {passengerSeats}
      {seat}
      on:openPaymentModal={() => {
        isOpen = false;
        showPaymentModal = true;
      }}
      on:openPaymentWarningModal={() => {
        showPaymentWarningModal = true;
      }}
      on:updateSelectedSeat
      on:unSelectSeat
    />
  {/if}
</div>

<Footer {advanceToNextScreen} bind:isOpen />
