<script>
  import { _ } from 'svelte-i18n';
  import { onDestroy, onMount } from 'svelte';
  import 'element-scroll-polyfill';
  import { get } from 'svelte/store';
  import { location, push } from 'svelte-spa-router';
  
  import {
    booking,
    checkInPassengersManager,
    SeatMapManager,
    seatChangesManager,
    setErrorModal,
    timeoutBlocked,
    currentPassengerForSeatMap
  } from '../../js/stores';
  import { OPERATOR_CODE } from '../../js/const';
  import { ErrorModals } from '../../js/const/errorModals';
  import flightdeck from '../../js/services/flightdeck';
  import logger from '../../js/logger';

  import Content from '../components/Content.svelte';
  import Footer from '../components/Footer/index.svelte';
  import Header from '../components/Header/index.svelte';
  import SeatMap from '../components/SeatMap/index.svelte';
  import OtherAirlineSeatMap from '../components/SeatMap/OtherAirline.svelte';
  import Selected from '../components/SeatMap/seats/Selected.svelte';

  const translate = get(_);

  let description = null;
  let flightNumber = null;
  let hasError = null;
  let isLoading = null;
  let isOpen = null;
  let passengers = [];
  let passenger = null; // supposed to represent the current pax
  let seat = null;
  let selectedSeatOffsetTopLocation = null;
  let passengerSeats = null;
  let previewSeat = null;
  let scrollable = null;
  let scrollableHeight = null;
  let seatMapManager = null;
  let segment = null;
  let segments = [];
  let selectedSeat = null;

  /**
   * Inject a passenger.currentSeat value.
   *
   * @param {object} oldPassengers
   */
  function injectCurrentSeat(oldPassengers) {
    return oldPassengers.map((passenger) => {
      return {
        ...passenger,
        currentSeat: booking.getSeatNumber(passenger, flightNumber),
      };
    });
  }

  /**
   * Retrieves the seat map.
   *
   * @example <caption>Example usage with dummyData.</caption>
   * onMount(() => {
   *   getSeatMap(`${flightNumber}-economy`);
   * });
   *
   * Refer to /stores/seatMap.js for dummyData params list.
   */
  function getSeatMaps(dummyData = null) {
    logger.info(`Request retrieveSeatMap. (flightNumber: ${flightNumber}).`);
    timeoutBlocked.set(true);
    isLoading = true;
    seatMapManager = new SeatMapManager(flightNumber);
    const seatMapRoute = get(location);
    seatMapManager
      .retrieveSeatMap()
      .then(() => {
        logger.info(`Recieved retrieveSeatMap.`);

        logger.info(passenger);
        seatMapManager.setCurrentSeatMap(passenger.passengerID);

        timeoutBlocked.set(false);
        isLoading = false;
      })
      .catch((error) => {
        logger.warn(`Failed retrieveSeatMap: ${error.message}`);
        if (get(location) !== seatMapRoute) {
          logger.info(
            'Failure of retrieveSeatMap occurred after user had already ' +
              'advanced from the seat map page. Ignoring.',
          );
          return;
        }
        flightdeck.seatChangeFailed();
        timeoutBlocked.set(false);
        setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
      });

  }

  function updatePassengerSeat(passenger, newSeatNumber) {
    $booking.passengers.forEach((p) => {
      if (p.passengerID === passenger.passengerID) {
        logger.info(`Inside updatePassengerSeat. Passengers matched. now updating seat from ${p.seatNumber} to ${newSeatNumber}`);
        p.seatNumber = newSeatNumber;
      }
    });
  }

  /**
   * Update values when data changes.
   *
   * @param {object} newPassenger
   * @param {object} newSegment
   */
  function updateValues({ newPassenger, newSegment } = {}) {
    if (newSegment) {
      flightNumber = newSegment.flightNumber;
      segment = newSegment;
    } else if (newPassenger) {
      selectedSeat = newPassenger.seatNumber;
      passenger = newPassenger;
    }
  }

  /**
   * Handle a seat being updated.
   *
   * @param {string} event - The dispatched event.
   */
  function updateSelectedSeatHandler(event) {
    currentPassengerForSeatMap.set(passenger);
    
    timeoutBlocked.set(true);
    isLoading = true;

    seatChangesManager
      .performChangeSeat(
        booking,
        passenger,
        flightNumber,
        event.detail.seat,
        flightdeck,
        event.detail.amount
      )
      .then((response) => {
        logger.info(`Retrieved performChangeSeat response. Response is: ${response}`);

        if (!response) {
          setErrorModal(ErrorModals.SEAT_CHANGE_FAILED);
          timeoutBlocked.set(false);
          isLoading = false;
          isOpen = false;
          previewSeat = null;
          return;
        }

        if (seatChangesManager.offloadOccurred()) {
          flightdeck.passengerOffloaded();
          setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
          return;
        }

        updatePassengerSeat(passenger, event.detail.seat);
        push('/please-wait-seat-map');
        timeoutBlocked.set(false);
        isLoading = false;
        isOpen = false;
        previewSeat = null;
      })
      .catch((error) => {
        logger.warn(`Failed performChangeSeat: Error: ${error.message}`);
        flightdeck.seatChangeFailed();
        timeoutBlocked.set(false);
        isLoading = false;
        isOpen = true;
        hasError = true;
      });
  }

  /**
   * Handle a passenger change.
   *
   * @param {string} event - The dispatched event.
   */
  function passengerChangeHandler(event) {
    previewSeat = null;
    passenger = event.detail.passenger;
    isOpen = false;
    updateValues({ newPassenger: passenger });
  }

  /**
   * Handle the scroll to seat dispatch.
   *
   * @param {string} event - The dispatched event.
   */
  function handleScrollToSeat(event) {
    if (event.detail.scrollable) {
      scrollable = event.detail.scrollable;
      scrollableHeight = event.detail.scrollable.clientHeight;
    } else if (event.detail.iconRef) {
      selectedSeatOffsetTopLocation = event.detail.iconRef.offsetTop;
    }

    scrollToSeat();
  }

  /** Scroll to the selected seat. */
  function scrollToSeat() {
    const centerOfScrollablePane = scrollableHeight / 2.75;
    const scrollYAmount =
      selectedSeatOffsetTopLocation - centerOfScrollablePane;

    scrollable.scrollTo(0, scrollYAmount);
  }

  /**
   * Handle a segment change.
   *
   * @param {string} event - The dispatched event.
   */
  function segmentSelectHandler(event) {
    segment = event.detail.segment;
    updateValues({ newSegment: segment });
    // Only call getSeatMap if segment.airlineCode is EY
    if (segment && segment?.airlineCode === OPERATOR_CODE) {
      seatMapManager.setCurrentSeatMap(passenger.passengerID);
    }
  }

  /**
   * Handle a seat being selected, but not confirmed.
   *
   * @param {string} event - The dispatched event.
   */
  function selectSeatHandler(event) {
    description = event.detail.description;
    selectedSeatOffsetTopLocation = event.detail.iconRef.offsetTop;
    isOpen = true;
    previewSeat = String(event.detail.seat);
    seat = event.detail.seat;
  }

  function unSelectSeatHandler() {
    isOpen = false;
    previewSeat = null;
    seat = null;
  }

  onMount(() => {
    description = '';
    hasError = false;
    isLoading = false;
    isOpen = false;
    passengers = booking.getPassengersExcludingInfants();
    [passenger] = passengers;

    let currentPassengerInStore = get(currentPassengerForSeatMap);
    if (currentPassengerInStore && currentPassengerInStore?.passengerID) {
      passenger = currentPassengerInStore; 
    }

    passengerSeats = [];
    seat = {};
    segments = booking.getDisplayedSegments(false);
    [segment] = segments;
    flightNumber = segment.flightNumber;
    selectedSeat = passenger.seatNumber
      ? passenger.seatNumber
      : translate('app.notApplicable');

    passengerSeats = booking.getPassengerSeats(flightNumber); // creates an array of allocated seat numbers like ['20A', '20B'] for 2 passengers
    selectedSeat = booking.getSeatNumber(passenger, flightNumber);
    passengers = injectCurrentSeat(passengers);
    
    getSeatMaps();
  });

  onDestroy(() => {
    timeoutBlocked.set(false);
  });
</script>

<Header />

<Content>
  <span slot="heading">{$_('seatMap.heading')}</span>
</Content>

<div class="mx-6">
  <SeatMap
    bind:isOpen
    bind:isLoading
    bind:hasError
    {segment}
    {description}
    {flightNumber}
    {passenger}
    {passengers}
    {passengerSeats}
    {previewSeat}
    {seat}
    {seatMapManager}
    {segments}
    {selectedSeat}
    on:passengerChange={passengerChangeHandler}
    on:updateSelectedSeat={updateSelectedSeatHandler}
    on:segmentSelect={segmentSelectHandler}
    on:selectSeat={selectSeatHandler}
    on:scrollToSeat={handleScrollToSeat}
    on:unSelectSeat={unSelectSeatHandler}
  />
</div>

<Footer />
